import invariant from 'invariant';
import { createContext, createElement, useContext, } from 'react';
import { useField } from './useField';
export function createAutoField(defaultComponentDetector) {
    const context = createContext(defaultComponentDetector);
    function AutoField(rawProps) {
        var _a, _b;
        const [props, uniforms] = useField(rawProps.name, rawProps);
        const componentDetector = useContext(context);
        const component = (_a = props.component) !== null && _a !== void 0 ? _a : componentDetector(props, uniforms);
        invariant(component, 'AutoField received no component for: %s', props.name);
        return 'options' in component && ((_b = component.options) === null || _b === void 0 ? void 0 : _b.kind) === 'leaf'
            ? createElement(component.Component, props)
            : createElement(component, rawProps);
    }
    return Object.assign(AutoField, {
        componentDetectorContext: context,
        defaultComponentDetector,
    });
}
